<template lang="html">
  <div>
    <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="changeCellLoading"/>

    <div :class='["tab", mobileTabCell ? "mobile-tab-cell" : ""]' v-show='isActive' v-if="selectedTabObj">
      <div class="site-btns main-buttons main-buttons-tab" v-if="!mobileTabCell">
        <md-button title="Add new site"
              @click="showCellModal(selectedTabObj)"
              data-v-step="8" class="md-icon-button tab-buttons">
          <md-icon><i class="fas fa-plus-square"></i></md-icon>
        </md-button>
        <md-button title="Edit site" data-v-step="7" class="md-icon-button tab-buttons" @click="editButtons('cell')">
          <md-icon><i class="fas fa-pen"></i></md-icon>
        </md-button>
          <md-button title="Change sites layout" v-if="selectedTabObj.tabCells.length > 0" class="md-icon-button tab-buttons" @click="changeView()">
            <md-icon v-if="this.isGrid"><i  class="fas fa-th-large"></i></md-icon>
            <md-icon v-if="this.isList"><i  class="fas fa-list"></i></md-icon>
            <md-icon v-if="this.isCondensed"><i class="fa fa-square-o"></i></md-icon>
          </md-button>
          <md-button v-show="this.canZoomIn" title="Zoom in" class="md-icon-button tab-buttons" @click="zoomIn()">
            <md-icon><i class="fas fa-search-plus"></i></md-icon>
          </md-button>
          <md-button v-show="this.canZoomOut" title="Zoom Out" class="md-icon-button tab-buttons" @click="zoomOut()">
            <md-icon><i class="fas fa-search-minus"></i></md-icon>
          </md-button>
      </div>
      <div v-if="!this.isListView" class="md-layout md-gutter md-alignment-left-left">
        <draggable v-model="selectedTabObj.tabCells"
                  :options="{draggable: '.editable'}"
                  style="display: inline-block;"
                  :style= "[mobileTabCell ? {'margin-left' : '0px'} : {'margin-left' : '25px'}]"
                  :class='{"public-user-cells": publicUsername != null  || adminMode}'
                  @change="onMoveChange">
          <div :class='{"md-layout-item cell-it": (editableCell === true || editableCellMobile  === true),
              "md-layout-item editable": (editableCell === false || editableCellMobile  === false),
              "cell-margin-bottom-condensed": isCondensed, "cell-margin-bottom-grid": isGrid}'
              v-for="(cell,cellIndex) in selectedTabObj.tabCells"
              :key="cell.id"
              :id="'cell-'+cell.id"
              :position="cell.position" data-v-step="6">
            <a :rel="cellRel" :href="editableCell ? !editableCellMobile ? 'javascript:;' : cell.link : 'javascript:;'"
              :title="linkOrAlt(cell)"
              :target="editableCell ? !editableCellMobile ? '' : '_blank' : ''">
                <div class="cell-container draggable edit-cell"
                :class="isCondensed ? 'cell-container-padding-condensed' : 'cell-container-padding-grid'"
                    v-show="(editableCell === false || editableCellMobile  === false)" v-if="!publicUsername  || adminMode || !isDifferentUser">
                  <i class="fas fa-arrows-alt"></i>
                  <i class="far fa-edit" @click="showCellModal(cell)"></i>
                </div>
                <div class="cell-container-outer"
                  :class="isCondensed ? 'cell-container-condensed' : 'cell-container-grid'">
                  <div
                  :class="(editableCell === false || editableCellMobile  === false) ? 'cell-container draggable opacity-link' : 'cell-container draggable'"
                  :style="{ 'background-image': 'url(' + selectedTabObj.public_path + ')', 'background-position': '0 ' + -(cellIndex)*25 + 'px', 'background-color': 'white' }">
                  </div>
                </div>
            </a>
          </div>
          <div class="cell-x md-layout-item" slot="footer"
              :class="isCondensed ? 'cell-margin-bottom-condensed' : 'cell-margin-bottom-grid'"
              style="padding-left: 0;"
              title="Add new site"
              v-if="(!publicUsername  || adminMode || !isDifferentUser)"
              @click="showCellModal(selectedTabObj)"
              v-show="(editableCell === true || editableCellMobile  === true)">
              <div class="cell-container-outer" :class="isCondensed ? 'cell-container-condensed' : 'cell-container-grid'">
                <div class="cell-container ripple plus-icon-cell"
                    :style="{ 'background-image': 'url(' + `${publicPath}plus-image-png.png` + ')', 'background-position': '2,5' + -(cellIndex)*25 + 'px' }">
                </div>
              </div>
          </div>
        </draggable>
      </div>
      <div v-else class="md-layout md-gutter md-alignment-left-left">
        <draggable v-model="selectedTabObj.tabCells"
                  :options="{draggable: '.editable'}"
                  style="display: inline-block;"
                  :style= "[mobileTabCell ? {'margin-left' : '0px'} : {'margin-left' : '25px'}]"
                  :class='{"public-user-cells": publicUsername != null  || adminMode}'
                  @change="onMoveChange">
              <div
              v-for="(cell,cellIndex) in selectedTabObj.tabCells"
              :class='{"md-layout-item cell-it": (editableCell === true || editableCellMobile  === true), "md-layout-item editable": (editableCell === false || editableCellMobile  === false)}'
              class="list-container"
              :key="cell.id"
              :id="'cell-'+cell.id"
              :position="cell.position" data-v-step="6">

              <div>
                <a :rel="cellRel" :href="editableCell ? !editableCellMobile ? 'javascript:;' : cell.link : 'javascript:;'"
                  :title="linkOrAlt(cell)"
                  :target="editableCell ? !editableCellMobile ? '' : '_blank' : ''">
                    <div class="cell-container draggable edit-cell"
                       :class="isCondensed ? 'cell-container-padding-condensed' : 'cell-container-padding-grid'"
                        v-show="(editableCell === false || editableCellMobile  === false)" v-if="!publicUsername  || adminMode || !isDifferentUser">
                      <i class="fas fa-arrows-alt"></i>
                      <i class="far fa-edit" @click="showCellModal(cell)"></i>
                    </div>
                  <div class="logo">
                    <div
                    :class="(editableCell === false || editableCellMobile  === false) ? 'cell-container draggable opacity-link' : 'cell-container draggable'"
                    :style="{ 'background-image': 'url(' + selectedTabObj.public_path + ')', 'background-position': '0 ' + -(cellIndex)*25 + 'px', 'background-color': 'white', }">
                    </div>
                  </div>
                </a>
              </div>
              <div>
                  <div class="alt">{{ linkOrAlt(cell) }}</div>
                  <a :href="cell.link" target='_blank'>
                  <div class="link alt">{{cell.link}}</div>
                  </a>
              </div>
          </div>
        </draggable>
      </div>
    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable';
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import changeCellLoaderMixin from '@/mixins/changeCellLoaderMixin';
import {
  ADMIN_MODE,
  SET_TAB_CELL_EDITABLE_ID_ADMIN,
  RESET_TAB_CELL_EDITABLE_ID_ADMIN,
} from '@/store/actions.type';
import {
  SET_TAB_CELL_EDITABLE_ID,
  UPDATE_TAB_CELL_POSITION,
  RESET_TAB_CELL_EDITABLE_ID,
  SET_SHARE_TAB_MODAL_VALUE,
} from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Tab',
  components: {
    draggable,
  },
  props: {
    hidden: {
      type: Boolean,
      default: true,
    },
    adminMode: Boolean,
    isDifferentUser: Boolean,
    mobileTabCell: Boolean,
    publicUsername: Object,
    selectedTabObj: Object,
    isGridMobile: Boolean,
    isListMobile: Boolean,
    editableCellMobile: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [changeCellLoaderMixin, smallDevicesCheckMixin],
  computed: {
    cellRel() {
      if (this.isDifferentUser) {
        return this.$store.getters.userData?.follow_links ? 'tag' : 'nofollow';
      }
      return this.$store.getters.user?.follow_links ? 'tag' : 'nofollow';
    },
    linkOrAlt() {
      return cell => (cell.alt !== null && cell.alt !== '' ? cell.alt : cell.link);
    },
    selectedTabIndex() {
      return this.$store.getters.selectedTabIndex;
    },
    isGrid() {
      return this.currentCellsView === this.cellsViewTypes.grid;
    },
    isCondensed() {
      return this.currentCellsView === this.cellsViewTypes.condensed;
    },
    isList() {
      return this.currentCellsView === this.cellsViewTypes.list;
    },
    isListView() {
      return this.isList || this.isListMobile;
    },
  },
  data() {
    return {
      isActive: true,
      publicPath: process.env.BASE_URL,
      cellIndex: null,
      editableCell: true,
      cellsViewTypes: {
        grid: 0,
        list: 1,
        condensed: 2,
      },
      currentCellsView: 0,
      canZoomOut: true,
      canZoomIn: true,
    };
  },
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  methods: {
    changeView() {
      /* eslint-disable-next-line no-plusplus */
      this.currentCellsView = (++this.currentCellsView % 3);
    },
    zoomIn() {
      const currZoom = document.body.style.zoom || 1;
      document.body.style.zoom = parseFloat(currZoom) + 0.1;
      this.canZoomIn = document.body.getBoundingClientRect().width > 1020;
    },
    zoomOut() {
      const currZoom = document.body.style.zoom || 1;
      document.body.style.zoom = parseFloat(currZoom) - 0.1;
      this.canZoomIn = document.body.getBoundingClientRect().width > 1020;
    },
    login() {
      this.$router.push({
        name: 'userLogin',
      });
    },
    editButtons(modalType) {
      if (this.$store.getters.user) {
        if (!this.isDifferentUser) {
          // Make Edit button visible
          if (modalType === 'cell') {
            this.editableCell = !this.editableCell;
          }
        } else {
          this.$toasted.show('Login to this account in order to edit.', {
            type: 'warning',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        }
      } else {
        this.login();
      }
    },
    showCellModal(value) {
      if (this.$store.getters.user) {
        if (!this.isDifferentUser) {
          this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false); // to avoid show the shard modal if you closed it by click outside
          this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID);
          this.$store.dispatch(RESET_TAB_CELL_EDITABLE_ID_ADMIN);
          if (this.adminMode) {
            this.$store.dispatch(ADMIN_MODE, true);
            this.$store.dispatch(SET_TAB_CELL_EDITABLE_ID_ADMIN, value.id);
          } else {
            this.$store.dispatch(ADMIN_MODE, false);
            this.$store.dispatch(SET_TAB_CELL_EDITABLE_ID, value.id); // tab id
          }
        } else {
          this.$toasted.show('Login to this account in order to add a site.', {
            type: 'warning',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        }
      } else {
        this.login();
      }
    },
    onMoveChange(itemMoved) {
      // contains information of an element moved
      const payload = {
        id: itemMoved.moved.element.id,
        position: itemMoved.moved.newIndex + 1,
        adminMode: !!this.adminMode,
      };
      this.$store
        .dispatch(UPDATE_TAB_CELL_POSITION, payload)
        .then((response) => {
          this.$log.info(UPDATE_TAB_CELL_POSITION, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(UPDATE_TAB_CELL_POSITION, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
  },
};
</script>

<style>
.site-btns{
  display: flex;
  flex-flow: column;
}

.cell-container-condensed {
  padding: 25px;
  margin-right: 3px;
}

.cell-container-grid {
  margin-right: 3px;
  padding: 3px;
}

.cell-margin-bottom-condensed {
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 10px;
  display: inline-block;
}

.cell-margin-bottom-grid {
  margin-bottom: 1rem;
  display: inline-block;
}


@media (min-width: 0px) and (max-width: 280px) {
  .cell-container-grid {
    padding: 14px;
    margin-right: 18px;
  }
  .mobile-tab-cell {
    margin-left: 5px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 10px;
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .cell-container-grid {
    padding: 13px;
  }
  .mobile-tab-cell {
    margin-left: 5px;
  }

  .cell-margin-bottom-grid {
    margin-bottom: 5px;
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .cell-container-grid {
    padding: 12px;
    margin-right: 14px;
  }
  .mobile-tab-cell {
    margin-left: 5px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 7px;
  }
}

@media (min-width: 390px) and (max-width: 413px) {
  .cell-container-grid {
    padding: 14px;
    margin-right: 14px;
  }
  .mobile-tab-cell {
    margin-left: 5px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 7px;
  }
}

@media (min-width: 412px) and (max-width: 429px) {
  .cell-container-grid {
    padding: 15px;
    margin-right: 18px;
  }
  .mobile-tab-cell {
    margin-left: 6px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 10px;
  }
}

@media (min-width: 430px) and (max-width: 539px) {
  .cell-container-grid {
    padding: 18px;
    margin-right: 18px;
  }
  .mobile-tab-cell {
    margin-left: 5px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 12px;
  }
}

@media (min-width: 540px) and (max-width: 700px) {
  .cell-container-grid {
    padding: 18px;
    margin-right: 15px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 12px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  .cell-container-grid {
    padding: 20px;
    margin-right: 20px;
  }
  .mobile-tab-cell {
    margin-left: 2px;
  }
  .cell-margin-bottom-grid {
    margin-bottom: 13px;
  }
}

@media (min-width: 768px) and (max-width: 809px) {
  .cell-container-grid {
    margin-right: 15px;
    padding: 13px;
  }
}

@media only screen and (min-width: 810px) {
  .cell-container-grid {
    margin-right: 9px;
    padding: 9px;
  }

  .cell-margin-bottom-condensed {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 910px) {
  .cell-container-grid {
    margin-right: 10px;
    padding: 15px;
  }

  .cell-margin-bottom-condensed {
    margin-right: 5px;
    padding: 6px;
  }
}

@media only screen and (min-width: 1024px) {
  .cell-container-grid {
    margin-right: 10px;
    padding: 10px;
  }
}

@media only screen and (min-width: 1280px) {
  .cell-container-grid {
    margin-right: 15px;
  }
}

.logo {
  border: 1px solid #D9D9D9;
  padding: 10px;
  margin-right: 15px;
  border-radius: 8px;
}

.list-container {
  display:flex;
  padding-bottom: 10px;
}

.icon-padding {
  padding: 8px 8px 6px 9px !important;
}

.alt {
  font-size: 16px;
  word-break: break-all;
}

@media only screen and (min-width: 1281px) {
  .public-user-cells {
    margin-left: 20px;
  }
}
.plus-icon-cell {
  background-size: 17px;
}
.plus-icon-cell:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>

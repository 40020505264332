<template>
  <md-card-content v-if="isLoading" class="spinner-container">
    <div class="md-layout md-alignment-center-center">
      <div id="loadingContainer" class="md-layout-item">
        <md-progress-bar class="md-accent" md-mode="indeterminate" />
      </div>
    </div>
  </md-card-content>

  <md-card v-else>
    <md-card-content v-if="!isAuthenticated && !publicUsername && currentRouteName !== 'myFaves'"
      class="user-tops-no-user">
      <div class="heading-tabs test">
        <div class="md-layout md-gutter md-alignment-center-space-around">
          <div class="md-layout-item" :style="isMobile ? { 'display': 'flex', 'justify-content': 'center' } : { '': '' }">
            <md-button v-show="isMobile" @click="login()" class="md-flat md-primary"
              v-if="(!publicUsername || adminMode)">
              <div>Simplify. Organize. Personalize. <br> Add your faves today.</div>
            </md-button>
            <md-button v-show="!isMobile" @click="login()" class="md-flat md-primary"
              v-if="(!publicUsername || adminMode)">
              <md-icon style="margin-right: 10px;"><i class="fas fa-plus"></i></md-icon>
              Simplify. Organize. Personalize. Add your faves today.
            </md-button>
          </div>
        </div>
      </div>
    </md-card-content>

    <md-card-content id="user-top-tabs-card" v-else>
      <div class="heading-tabs test">
        <div class="md-layout md-gutter md-alignment-center-left">
          <div class="md-layout-item md-size-100 tabs-container">
            <div class="main-buttons tabs-btns">

              <div v-show="!this.userHasTabs" class="md-layout md-gutter md-alignment-center-space-around">
                <div class="md-layout-item">
                  <div class="md-layout-item">
                    <p> No Tabs Yet.</p>
                  </div>
                </div>
              </div>

              <md-button v-show="this.userHasTabs" v-if="!isMobile" class="toggle-tabs-menu md-icon-button tab-buttons"
                @click="toggleMenu()">
                <md-icon title="Show/Hide tabs">
                  <i v-if="showMenu" class="tabs-menu-btn-icon fas fa-times"></i>
                  <i v-else class="tabs-menu-btn-icon fas fa-bars"></i>
                </md-icon>
              </md-button>

              <md-button title="Sort tabs" v-show="this.userHasTabs" v-if="!isMobile"
                class="toggle-tabs-menu md-icon-button tab-buttons" @click="sortTabs()">
                <md-icon>
                  <i
                    :class="[this.currentSort === this.sortTypes.original ? 'fas fa-sort' : this.currentSort === this.sortTypes.asc ? 'fas fa-chevron-up' : 'fas fa-chevron-down']"></i>
                </md-icon>
              </md-button>

              <md-button title="Add new tab" data-v-step="3"
                v-if="((publicUsername !== null) && (!publicUsername || adminMode)) || (!isDifferentUser)"
                class='md-icon-button tab-buttons' @click="showTabModalForAddNewTab">
                <md-icon> <i class="far fa-plus-square tabs-menu-btn-icon "></i></md-icon>
              </md-button>

              <md-button title="Edit tab" data-v-step="5" v-show="this.userHasTabs"
                v-if="((publicUsername !== null) && (!publicUsername || adminMode)) || (!isDifferentUser)"
                class='md-icon-button tab-buttons' @click="showTabModalForEditTab">
                <md-icon><i class="fas fa-pen"></i></md-icon>
              </md-button>

              <regenerate-sprite-btn title="Regenerate tab logos" v-show="this.userHasTabs"
                v-if="((publicUsername !== null) && (!publicUsername)) || (!isDifferentUser && !adminMode)"
                componentView="icon" :userId="userId"></regenerate-sprite-btn>

              <md-button title="Share tab link" v-show="this.userHasTabs" v-if="!isPrivate"
                class='md-icon-button tab-buttons' @click="showShareModal">
                <md-icon><i class="fas fa-share"></i></md-icon>
              </md-button>

              <md-button title="Change sites layout" v-if="this.userHasTabs > 0 && isMobile" class="md-icon-button tab-buttons" @click="changeView()">
                <md-icon v-if="this.isGrid"><i  class="fas fa-th-large"></i></md-icon>
                <md-icon v-if="this.isList"><i  class="fas fa-list"></i></md-icon>
              </md-button>

              <p v-show="selectedTabDescription" class="selected-tabs-description">
                {{ this.selectedTabDescription }}
              </p>

            </div>

            <tabsDesktop :showMenu="showMenu" v-if="!isMobile" :editableTab="editableTab" :publicUsername="publicUsername"
              :adminMode="adminMode" :isDifferentUser="isDifferentUser" @setUserHasTabs="setUserHasTabs"
              @setSelectedTabDescription="setSelectedTabDescription" :currentSort="currentSort" :sortTypes="sortTypes"
              @close="close" @resetSort="resetSort"></tabsDesktop>

            <tabsMobile v-else :editableTab="editableTab" :publicUsername="publicUsername"
              @setSelectedTabDescription="setSelectedTabDescription" :adminMode="adminMode"
              @setUserHasTabs="setUserHasTabs" :isDifferentUser="isDifferentUser"
              @close="close"
              :isGridMobile="isGrid" :isListMobile="isList"
              ></tabsMobile>
          </div>
        </div>
      </div>
    </md-card-content>

  </md-card>
</template>

<script>
import TabsDesktop from '@/components/tabs-template/Tabs.vue';
import TabsMobile from '@/components/tabs-template/TabsMobile.vue';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';
import smallDevicesCheckMixin from '@/mixins/smallDevicesCheckMixin';
import loaderMixin from '@/mixins/loaderMixin';
import RegenerateSpriteBtn from '@/components/common/user/RegenerateSpriteBtn.vue';
import { SET_TAB_MODAL_VALUE, SET_SHARE_TAB_MODAL_VALUE, SET_EDITABLE_TAB_MODAL_VALUE } from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'UserTopTabs',
  components: {
    TabsDesktop,
    TabsMobile,
    RegenerateSpriteBtn,
  },
  mixins: [
    loggedInUserMixin,
    smallDevicesCheckMixin,
    loaderMixin,
  ],
  props: {
    publicUsername: {
      type: Object,
    },
    adminMode: {
      type: Boolean,
    },
    isDifferentUser: {
      type: Boolean,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      windowWidth: null,
      showMenu: true,
      userHasTabs: false,
      selectedTabDescription: null,
      currentCellsView: 0,
      cellsViewTypes: {
        grid: 0,
        list: 1,
      },
      sortTypes: {
        original: 0,
        asc: 1,
        desc: 2,
      },
      currentSort: 0,
    };
  },
  mounted() {
    this.checkDevice();
    this.smallDeviceCheck();
  },
  computed: {
    isGrid() {
      return this.currentCellsView === this.cellsViewTypes.grid;
    },
    isList() {
      return this.currentCellsView === this.cellsViewTypes.list;
    },
    currentRouteName() {
      return this.$route.name;
    },
    editableTab() {
      return this.$store.getters.isEditableModalVisibleTab;
    },
    userId() {
      return this.$store.getters.user ? this.$store.getters.user.id : null;
    },
    isPrivate() {
      const tab = this.$store.getters.selectedTab;
      return tab?.privatetab;
    },
  },
  methods: {
    changeView() {
      /* eslint-disable-next-line no-plusplus */
      this.currentCellsView = (++this.currentCellsView % 2);
    },
    login() {
      this.$router.push({
        name: 'userLogin',
      });
    },
    showTabModalForEditTab() {
      this.close();
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, true);
    },
    showShareModal() {
      this.close();
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, true);
    },
    showTabModalForAddNewTab() {
      this.close();
      this.$store.dispatch(SET_TAB_MODAL_VALUE, true);
    },
    toggleMenu() {
      this.close();
      this.showMenu = !this.showMenu;
    },
    close() {
      // cuz state isnot reset to false if you close it by clicking outside
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_SHARE_TAB_MODAL_VALUE, false);
    },
    setUserHasTabs(value) {
      this.userHasTabs = value;
    },
    setSelectedTabDescription(description) {
      this.selectedTabDescription = description;
    },
    sortTabs() {
      if (this.sortTypes.original === this.currentSort) {
        this.currentSort = this.sortTypes.asc;
      } else if (this.sortTypes.asc === this.currentSort) {
        this.currentSort = this.sortTypes.desc;
      } else if (this.sortTypes.desc === this.currentSort) {
        this.currentSort = this.sortTypes.original;
      }
    },
    resetSort() {
      this.currentSort = this.sortTypes.original;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/site-autosuggest.scss';

#user-top-tabs-card .md-card-content-mobile {
  padding: 0;
}

.tabs-container {
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-flow: column;
}

.tabs-btns p {
  color: #1020A9;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

@media (max-width: 760px) {
  .tabs-container {
    padding: 0px;
  }

  .md-app-content .md-card {
    margin: 0;
  }
}</style>

<template lang="html">
  <div>
    <div v-if="this.userTabsAttr.length > 0" class="tabs_container">
      <ul v-if="showMenu" class='tabs__header' :style="dynaminHeightForTabsList">
        <li v-for='(tab, index) in this.userTabsAttr.slice(0, 6)' :key='tab.id' :id="'tab-' + tab.id"
          :position="tab.position" :class='{
            "tab__selected": (index == selectedIndex), "visibleForAll": (hideLi == 1), "hidden": (hideLi == 0),
            "hidden-without-slot": (!tab.position && tab.position !== 0)
          }' @click='selectTab(index)' :title="tab.description" data-v-step="2">
          {{ tab.name }}
        </li>
        <li title="Show More" id="show-more" @click="showMore()" v-show="hideLi == 0 && this.userTabsAttr.length > 6">
          More <i class="fas fa-caret-left"></i></li>
        <li title="Show Less" id="show-less" @click="showLess()" v-show="hideLi == 1 && this.userTabsAttr.length > 6">
          Less <i class="fas fa-caret-down"></i></li>
        <li v-for='(tab, index) in this.userTabsAttr.slice(6, this.userTabsAttr.length)' :key='tab.id'
          :id="'tab-' + tab.id" :position="tab.position" :class='{
            "tab__selected": ((index + 6) == selectedIndex), "visibleForAll": (hideLi == 1), "hidden": (hideLi == 0),
            "hidden-without-slot": (!tab.position && tab.position !== 0)
          }' @click='selectTab(index + 6)' :title="tab.description">
          {{ tab.name }}
        </li>
      </ul>

      <TabCell style="width: 100%;" :selectedTabObj="selectedTabObj" :publicUsername="publicUsername"
        :isDifferentUser="isDifferentUser" :adminMode="adminMode">
      </TabCell>
    </div>

    <div v-else class="md-layout md-gutter md-alignment-center-space-around">
      <div class="md-layout-item">
        <div class="md-layout-item">
          <p>
            This User hasn't any public tabs yet.
          </p>
        </div>
      </div>
    </div>

    <TabModal v-if="!publicUsername || adminMode || !isDifferentUser" :position="lastElPosition.position"
      :editableTab="editableTab" :adminMode="adminMode" :currentEditTabObj="currentEditTabObj" @close="close"
      :tab="userTabObj" :tabId="tabId" :currentTabsNumber="this.userTabsAttr.length"
      :isModalVisibleTab="isModalVisibleTab" v-show="isModalVisibleTab">
    </TabModal>
  </div>
</template>

<script>
import TabModal from '@/components/modals/TabModal.vue';
import TabCell from '@/components/tabs-template/TabCell.vue';
import LocalStorageService from '@/services/localStorage.service';
import {
  SET_SELECTED_TAB, SET_EDITABLE_TAB_MODAL_VALUE, SET_SELECTED_TAB_INDEX, SET_TAB_MODAL_VALUE,
} from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Tabs',
  components: {
    TabCell,
    TabModal,
  },
  props: {
    editableTab: Boolean,
    publicUsername: Object,
    adminMode: Boolean,
    isDifferentUser: Boolean,
    showMenu: Boolean,
    newTab: Boolean,
    currentSort: Number,
    sortTypes: Object,
  },
  data() {
    return {
      selectedIndex: this.$store.getters.selectedTabIndex,
      // Tab variables
      currentEditTabObj: null,
      selectedTabObj: null, // selected tab to pass to tab cell
      tabs: [],
      userTabObj: null,
      tabId: null,
      addButton: 1,
      addSecondButton: 0,
      publicPath: process.env.BASE_URL,
      hideLi: LocalStorageService.getShowMoreTabs(),
      lastElPosition: 0, // find the cell last position for the new cell
      userTab: {},
      userTabsAttr: [],
      originalTabs: [],
    };
  },
  computed: {
    userTabs() {
      // we need to watch it in order to update the state when we add, edit, remove tab or cell
      return this.$store.getters.userTabs;
    },
    isModalVisibleTab() {
      return this.$store.getters.isModalVisibleTab
        || this.$store.getters.isEditableModalVisibleTab;
    },
    dynaminHeightForTabsList() {
      const { tabCells } = this.selectedTabObj ? this.selectedTabObj : [];
      const tabCellscellRows = tabCells ? tabCells.length / 8 : 2;
      let maxHeight = (tabCellscellRows - 1) * 80;
      maxHeight = maxHeight < 300 ? 300 : maxHeight;

      return `max-height: ${maxHeight}px`;
    },
  },
  mounted() {
    this.setUserTabs();
    let i = this.$store.getters.selectedTabIndex;
    const id = this.$route.hash;
    if (id !== undefined && id !== null && id.includes('tab')) {
      const tabId = parseInt(id.substring(5), 10);
      i = this.userTabsAttr.findIndex(tab => tab.id === tabId);
    }
    // -1 means wrong tab id in the url, so fetch from the index from the state
    i = (i === -1) ? this.$store.getters.selectedTabIndex : i;
    this.selectTab(i);
    // Find that highest position of the tabs
    if (this.userTabsAttr.length > 0) {
      this.lastElPosition = this.userTabsAttr.reduce((prev, current) => {
        if (+current.position > +prev.position) {
          return current;
        }
        return prev;
      });
    }
  },
  methods: {
    setUserTabs() {
      // Get the user tabs from the store
      const { userTabs } = this.$store.getters.userData;
      const currentUser = this.$store.getters.user;

      // Check the different scenarios and assign the userTabsAttr accordingly
      if (this.adminMode) {
        // Admin mode: show all tabs
        this.userTabsAttr = userTabs;
      } else if (this.publicUsername) {
        // Myfaves page: show only public tabs for other users, or all tabs for current user
        const isCurrentUser = currentUser?.id === this.publicUsername.id;
        this.userTabsAttr = isCurrentUser ? this.$store.getters.userTabs : userTabs.filter(tab => !tab.privatetab);
      } else {
        // Default case: show the user tabs from the store
        this.userTabsAttr = this.$store.getters.userTabs;
      }

      // Save the original tabs for later use
      this.originalTabs = this.userTabsAttr;
      this.setUserHasTabs(this.userTabsAttr.length > 0);
    },
    getIndexOfTabByName(tabs, tabName) {
      return tabs.findIndex(tab => tab.name === tabName);
    },
    selectTab(i) {
      const payload = { selectedTabIndex: i };
      this.$store.dispatch(SET_SELECTED_TAB_INDEX, payload);
      this.selectedIndex = i;
      // loop over all the tabs
      this.userTabsAttr.forEach((tab, index) => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = (index === i);
      });
      this.setCurrentEditTabObj(this.userTabsAttr[i]);

      // close the premium modal once we open it, then go outside of it
      // in this case, the open stats will be true so we must return it to false (default)
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
    },
    showMore() {
      this.hideLi = 1;
      this.addButton = 0;
      this.addSecondButton = 1;
      LocalStorageService.setShowMoreTabs(true);
    },
    showLess() {
      this.hideLi = 0;
      this.addButton = 1;
      this.addSecondButton = 0;
      LocalStorageService.setShowMoreTabs(false);
    },
    setCurrentEditTabObj(tab) {
      if (tab) {
        this.tabId = tab.id; // Get Id for Add Modal
        // Used only on Edit. The current tab obj is converted into an array because of the "deep" watch flag into tabmodal child component
        const tabArr = {
          position: tab.position,
          tabId: tab.id,
          name: tab.name,
          description: tab.description,
          privatetab: tab.privatetab,
          id: this.$store.getters.userData.id,
          tabCells: tab.tabCells,
        };
        const objectArray = Object.entries(tabArr);
        this.currentEditTabObj = tab ? objectArray : null;
        this.selectedTabObj = tab;
        this.$store.dispatch(SET_SELECTED_TAB, tab);

        this.setSelectedTabDescription(tab.description);
      }
    },
    editButtons(modalType) { // Make Edit button visible
      if (modalType === 'cell') {
        this.editableCell = !this.editableCell;
      }
    },
    close() {
      this.isModalVisibleCell = false;
      this.$emit('close');
    },
    setUserHasTabs(value) {
      this.$emit('setUserHasTabs', value);
    },
    setSelectedTabDescription(value) {
      this.$emit('setSelectedTabDescription', value);
    },
    sortTabs(val) {
      const sortedAsc = [...this.originalTabs];
      const sortedDesc = [...this.originalTabs];

      if (this.sortTypes.original === val) {
        this.userTabsAttr = this.originalTabs;
      } else if (this.sortTypes.asc === val) {
        this.userTabsAttr = sortedAsc.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      } else if (this.sortTypes.desc === val) {
        this.userTabsAttr = sortedDesc.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
      }
      this.selectTab(this.selectedIndex);
    },
  },
  watch: {
    publicUsername: {
      handler(val) {
        if (val) {
          this.setUserTabs();
          if (this.userTabsAttr.length > 0) {
            const tabName = this.$route.query?.tab;
            let idx = this.getIndexOfTabByName(this.userTabsAttr, tabName);
            idx = (idx !== -1) ? idx : 0;
            this.selectTab(idx);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    currentSort: {
      handler(val) {
        this.sortTabs(val);
      },
      deep: true,
      immediate: true,
    },
    userTabs: {
      handler() {
        this.setUserTabs();
        this.selectTab(this.selectedIndex);
        this.$emit('resetSort');
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/user-top-tabs.scss';
</style>

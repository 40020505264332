<template>
  <div>
    <md-dialog :md-active.sync="isModalVisibleTab" class="md-dialog-tab"
            @keydown.esc="close">
      <form v-if="!upgradeModal" novalidate @submit.prevent="validateSaveTab">
        <md-dialog-title class="modal-header"> {{ $t('userTopTabs.tabDetails') }}
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
          <span class="material-icons">close</span>
          </button>
        </md-dialog-title>

        <md-dialog-content>
          <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading"/>
          <md-content class="modal-body"
                      id="modalDescription">
            <input class="modal-input form-control autosuggest__input input-with-border" name="name"
                   id="name"
                   type="text"
                   v-model="form.name"
                   placeholder="Name"
            />
            <div class="md-error" v-if="!$v.form.name.required && requiredFields">
              {{ $t('missingValue') }}
            </div>
            <input class="modal-input form-control autosuggest__input input-with-border" name="description"
                   id="description"
                   type="text"
                   v-model="form.description"
                   placeholder="Description"
            />
            <p v-show="this.editableTab"
             style="color: #1020A9;
                margin-top: 0px;
                transform: translateY(10px);">
                Tab position
            </p>
            <input class="modal-input form-control autosuggest__input input-with-border" name="position"
                   id="position"
                   type="number"
                   min="1"
                   pattern="[1-9]*"
                   v-model="form.position"
                   v-show="this.editableTab"
                   placeholder="Position"
                   title="Tab's Order"
            />
          </md-content>
        </md-dialog-content>

        <md-dialog-actions class="modal-footer">
          <div class="md-layout md-buttons-modal">
            <div class="align-left md-layout-item cell-modal-icons-container tab-modal-container">
              <i
                 :class="[ this.editableTab ? 'icon-space-right' : '' ]"
                 v-show="this.editableTab" @click="deleteTabConfirmation = true;">
                <div class="basic-btn trash-on-tab-modal-btn" title="Delete">
                </div>
              </i>
              <i v-show="!this.private" @click="publicTab"
                 :class="[ !this.editableTab ? 'align-public' : 'icon-space-right' ]">
                <div class="basic-btn public-btn" title="Visible">
                </div>
              </i>
              <i v-show="this.private" @click="publicTab"
                 :class="[ !this.editableTab ? 'align-public' : 'icon-space-right' ]">
                <div class="basic-btn private-btn" title="Not Visible">
                </div>
              </i>
              <transition name="fade">
                <i class="icon-space-right" v-show="this.editableTab && !this.private"
                   @click="shareTab(this.form.name,true)">
                  <div class="basic-btn share-btn" title="Share">
                  </div>
                </i>
              </transition>
              <br>
              <transition name="fade">
                <div class="inline-width share-input-container" v-show="this.editableTab && !this.private">
                  <input class="margin-to-align shareURL" readonly v-model="generatedShareURL">
                  <a href="#" class="share-description" @click="shareTab(form.name,true)">{{
                      $t('userTopTabs.copy')
                    }}</a>
                </div>
              </transition>
            </div>
            <md-button class="md-raised md-primary site-save-button" type="submit" style="margin-bottom: 30px;">
              {{ $t('userTopTabs.save') }}
            </md-button>
          </div>
        </md-dialog-actions>
      </form>

      <subscription-modal v-else @close="close"
      limitType="tabs"></subscription-modal>
    </md-dialog>

    <md-dialog-confirm class="account-delete-confirmation delete-tab"
                       :md-active.sync="deleteTabConfirmation"
                       md-title="Delete Tab"
                       :md-content="deleteItem"
                       md-confirm-text="Yes"
                       md-cancel-text="No"
                       @md-confirm="deleteTab" />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import {
  CREATE_USER_TAB,
  DELETE_USER_TAB,
  UPDATE_USER_TAB,
} from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import SubscriptionModal from './SubscriptionModal.vue';

export default {
  name: 'TabModal',
  components: {
    SubscriptionModal,
  },
  props: {
    adminMode: Boolean,
    userTabObj: Object,
    position: {
      type: [Number, String],
    },
    editableTab: Boolean,
    tabId: Number,
    currentEditTabObj: {
      type: [Object, PointerEvent, Array],
    },
    currentTabsNumber: Number,
    isModalVisibleTab: Boolean,
  },
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      id: null,
      description: null,
      name: null,
      position: 0,
    },
    deleteTabConfirmation: false,
    requiredFields: false, // Check required fields
    private: false, // Private tab
    editTabId: null, // current edited tab id
    publicPath: process.env.BASE_URL,
    generatedShareURL: null,
  }),
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  computed: {
    deleteItem() {
      return `Do You want To delete ${this.form.name} tab?`;
    },
    isAdmin() {
      return this.$store.getters.user.role === 'admin';
    },
    canAddMoreTabs() {
      return (this.$store.getters.user.plan_tabs_number)
        ? this.currentTabsNumber < this.$store.getters.user.plan_tabs_number
        : true; // unlimited plan
    },
    upgradeModal() {
      return (!this.editableTab && !this.canAddMoreTabs && !this.isAdmin);
    },
  },
  methods: {
    close() {
      this.$v.$reset();
      this.form.name = null;
      this.form.description = null;
      this.$emit('close');
    },
    validateSaveTab() {
      this.$v.$touch();
      this.errors = {};
      if (!this.$v.$invalid) {
        this.saveTab();
      } else {
        this.requiredFields = true;
      }
    },
    saveTab() { // Same method is used for add and edit tabs using ternary operator in dispatch
      this.form.position = this.form.position === 0 ? 1 : this.form.position;
      // eslint-disable-next-line radix
      if (parseInt(this.form.position) === 0) {
        this.$toasted.show('The tab position cannot be 0! Please select another number.', {
          type: 'error',
          position: 'top-right',
          duration: 5000,
          icon: 'alert',
        });
      } else {
        const payload = {
          id: this.form.id,
          user_id: this.adminMode && !this.editableTab ? this.form.id : null,
          name: this.form.name,
          description: this.form.description,
          adminMode: this.adminMode,
          // eslint-disable-next-line radix
          position: this.editableTab ? parseInt(this.form.position) : this.position + 1,
          privatetab: this.private ? 1 : 0,
          tabId: this.editTabId ?? this.editTabId,
        };
        this.$store.dispatch(this.editableTab ? UPDATE_USER_TAB : CREATE_USER_TAB, payload)
          .then((response) => {
            this.$log.info(this.editableTab ? UPDATE_USER_TAB : CREATE_USER_TAB, payload);
            this.$toasted.show(response.message, {
              type: 'success',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
            });
            this.clearForm();
          })
          .catch((error) => {
            this.$log.error(this.editableTab ? UPDATE_USER_TAB : CREATE_USER_TAB, error);
            this.$toasted.show(error.message, {
              type: 'error',
              position: 'top-right',
              duration: 5000,
              icon: 'alert',
            });
            if (error.status === 'validation_error' && error.errors) {
              this.errors = error.errors;
            }
          });
      }
    },
    clearForm() {
      this.close();
      this.$v.$reset();
      this.form.name = null;
      this.form.description = null;
      this.form.position = null;
    },
    publicTab() {
      this.private = !this.private;
      this.shareTab(this.form.name, false);
    },
    deleteTab() {
      const payload = {
        editTabId: this.editTabId,
        adminMode: this.adminMode,
      };
      this.$store.dispatch(DELETE_USER_TAB, payload)
        .then((response) => {
          this.$log.info('DELETE_USER_TAB', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
        })
        .catch((error) => {
          this.$log.error('DELETE_USER_TAB', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
    shareTab(tabName, privateButtonMode) {
      const baseUrl = window.location.origin;
      const { username } = this.$store.getters.user;
      const generatedURL = `${baseUrl}/${username}?tab=${encodeURIComponent(tabName)}`;
      this.generatedShareURL = generatedURL;
      if (privateButtonMode) { // Copy the url only if share button is clicked. The sharetab method is called when the public/private button is clicked to generate the link
        this.copyURL(this.generatedShareURL);
      }
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toasted.show('The tab link has been copied! You can now paste it anywhere you like!', {
          type: 'success',
          duration: 5000,
          icon: 'check',
        });
      } catch ($e) {
        this.$toasted.error('The tab link hasn\'t been copied!', {
          type: 'error',
          duration: 5000,
          icon: 'alert',
        });
      }
    },
    fillInputInEditCase(tab) {
      if (tab) {
        tab.forEach(([key, value]) => {
          if (key === 'id') {
            this.form.id = value;
          }
          if (key === 'name') {
            this.shareTab(this.editableTab ? value : null, false);
            this.form.name = this.editableTab ? value : null;
          }
          if (key === 'position') {
            this.form.position = value;
          }
          if (key === 'tabId') {
            this.editTabId = value;
          }
          if (key === 'description') {
            this.form.description = this.editableTab ? value : null;
          }
          if (key === 'privatetab') {
            this.private = this.editableTab ? value : null;
          }
        });
      }
    },
  },
  watch: {
    isModalVisibleTab: {
      handler(isModalVisibleTab) { // add / edit cases
        if (isModalVisibleTab) {
          this.fillInputInEditCase(this.currentEditTabObj);
        } else {
          this.fillInputInEditCase([]);
        }
      },
      deep: true,
    },
  },
};
</script>


<style scoped>
.icon-space-right {
  margin-right: 15px;
}

.align-left {
  padding-left: 35px !important;
}

.margin-to-align {
  margin: 10px 0px 0px 10px;
}

.inline-width {
  width: 75%;
}
</style>

<template lang="html">
  <div>
    <div v-if="userTabs.length > 0" class="mobile-tab">
      <ul class='tabs__header' >
        <li v-for='(tab, index) in userTabs'
            :id="tab.id"
            :key='tab.id'
            :position="tab.position"
            :class='{"tab__selected": (index === selectedIndex),"visibleForAllMobile" : (hideLi == 1), "hidden": (hideLi == 0),
            "hidden-without-slot":(!tab.position && tab.position !== 0)}'
            :title="tab.description" data-v-step="2">
            <div class="tab-header">
                <div @click='selectTab(index, tab.id)'>
                  <div class="cell-container draggable edit-cell edit-tab"
                    v-show="editableTab && tab.id && (tab.position || tab.position === 0)">
                    <i class="far fa-edit"></i>
                  </div>
                  {{ tab.name }}
                  <transition name="fade">
                  <i class="fas fa-chevron-up" v-if="index === selectedIndex"></i>
                  <i class="fas fa-chevron-down" v-if="index !== selectedIndex"></i>
                  </transition>
                </div>
              <transition name="slide-fade">
                <div v-if="index === selectedIndex" class="button-container-edit">
                  <md-button v-if="!publicUsername || adminMode || !isDifferentUser"
                            class="md-icon-button tab-buttons main-buttons ripple"
                            @click="editCell"  data-v-step="5">
                    <md-icon><i class="fas fa-edit"></i></md-icon>
                  </md-button>
                </div>
              </transition>
            </div>
          <transition name="fade">
            <tabCell v-if="index === selectedIndex" :selectedTabObj="selectedTabObj" :isDifferentUser="isDifferentUser"
            :editableCellMobile="!editableCell" :publicUsername="publicUsername" :adminMode="adminMode" :mobileTabCell="true"
            :isListMobile="isListMobile" :isGridMobile="isGridMobile"
            ></tabCell>
          </transition>
        </li>
        <li title="Show More" id="show-more" @click="showMore()" v-show="hideLi == 0 && !editableTab && userTabs.length > 6" >
          More <i class="fas fa-caret-left"></i></li>
        <li title="Show Less" id="show-less" @click="showLess()" v-show="hideLi == 1 && !editableTab && userTabs.length > 6" >
          Less <i class="fas fa-caret-down"></i></li>
        <br>
      </ul>
    </div>

    <TabModal
        v-if="!publicUsername || adminMode || !isDifferentUser"
        :adminMode="adminMode"
        :position="lastElPosition.position"
        :editableTab="editableTab"
        :currentEditTabObj="currentEditTabObj"
        v-show="isModalVisibleTab"
        :isModalVisibleTab="isModalVisibleTab"
        @close="closeModal"
        :tab="userTabObj"
        :tabId="tabId"
        :currentTabsNumber="userTabs.length">
      </TabModal>
  </div>

</template>

<script>

import TabModal from '@/components/modals/TabModal.vue';
import tabCell from '@/components/tabs-template/TabCell.vue';
import LocalStorageService from '@/services/localStorage.service';
import {
  SET_SELECTED_TAB, SET_SELECTED_TAB_INDEX, SET_EDITABLE_TAB_MODAL_VALUE, SET_TAB_MODAL_VALUE,
} from '../../store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Tabs',
  components: {
    TabModal,
    tabCell,

  },
  props: {
    editableTab: Boolean,
    publicUsername: Object,
    adminMode: Boolean,
    isDifferentUser: Boolean,
    isGridMobile: Boolean,
    isListMobile: Boolean,
  },
  data() {
    return {
      editableCell: false,
      selectedTabObj: null, // selected tab to pass to tab cell
      selectedIndex: this.$store.getters.selectedTabIndex,
      currentEditTabObj: null,
      tabs: [],
      userTabObj: null,
      tabId: null,
      addButton: 1,
      addSecondButton: 0,
      publicPath: process.env.BASE_URL,
      hideLi: LocalStorageService.getShowMoreTabs(),
      lastElPosition: 0, // find the cell last position for the new cell
      userTab: {},
    };
  },
  computed: {
    isModalVisibleTab() {
      return this.$store.getters.isModalVisibleTab
      || this.$store.getters.isEditableModalVisibleTab;
    },
    userTabs() {
      // check if the logged user is not the same with the user from url
      if (this.adminMode) {
        return this.$store.getters.userData.userTabs;
      }
      // myfaves page
      if (this.publicUsername) {
        if (this.$store.getters.user && this.$store.getters.user.id === this.publicUsername.id) {
          // myfaves page but for the current logged in user
          return this.$store.getters.userTabs;
        }
        // display the tabs for the user from url (public user) && hide private tabs
        // eslint-disable-next-line array-callback-return
        return this.publicUsername.userTabs.filter(tab => !tab.privatetab);
      }
      // homepage
      if (this.publicUsername === undefined) {
        // auth user usertabs
        return this.$store.getters.userTabs;
      }
      // /username - or for any other case, no tabs right now.
      return [];
    },
  },
  mounted() {
    let i = this.$store.getters.selectedTabIndex;
    const id = this.$route.hash;
    if (id !== undefined && id !== null && id.includes('tab')) {
      const tabId = parseInt(id.substring(5), 10);
      i = this.userTabs.findIndex(tab => tab.id === tabId);
    }
    // -1 means wrong tab id in the url, so fetch from the index from the state
    i = (i === -1) ? this.$store.getters.selectedTabIndex : i;
    this.selectTab(i);
    // Find that highest position of the tabs
    if (this.userTabs.length > 0) {
      this.lastElPosition = this.userTabs.reduce((prev, current) => {
        if (+current.position > +prev.position) {
          return current;
        }
        return prev;
      });
    }
  },
  methods: {
    getIndexOfTabByName(tabs, tabName) {
      return tabs.findIndex(tab => tab.name === tabName);
    },
    editCell() {
      this.editableCell = !this.editableCell;
    },
    selectTab(i) {
      const payload = { selectedTabIndex: i };
      this.$store.dispatch(SET_SELECTED_TAB_INDEX, payload);
      this.selectedIndex = i;
      // loop over all the tabs
      this.userTabs.forEach((tab, index) => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = (index === i);
      });
      this.setCurrentEditTabObj(this.userTabs[i]);

      // close the premium modal once we open it, then go outside of it
      // in this case, the open stats will be true so we must return it to false (default)
      this.$store.dispatch(SET_TAB_MODAL_VALUE, false);
      this.$store.dispatch(SET_EDITABLE_TAB_MODAL_VALUE, false);
    },
    showMore() {
      this.hideLi = 1;
      this.addButton = 0;
      this.addSecondButton = 1;
      LocalStorageService.setShowMoreTabs(true);
    },
    showLess() {
      this.hideLi = 0;
      this.addButton = 1;
      this.addSecondButton = 0;
      LocalStorageService.setShowMoreTabs(false);
    },
    setCurrentEditTabObj(tab) { // Tab Modal on add & edit
      if (tab) {
        this.tabId = tab.id; // Get Id for Add Modal
        // Used only on Edit. The current tab obj is converted into an array because of the "deep" watch flag into tabmodal child component
        const tabArr = {
          position: tab.position,
          tabId: tab.id,
          name: tab.name,
          description: tab.description,
        };
        const objectArray = Object.entries(tabArr);
        this.currentEditTabObj = tab ? objectArray : null;
        this.selectedTabObj = tab;
        this.$store.dispatch(SET_SELECTED_TAB, tab);

        this.setSelectedTabDescription(tab.description);
      }
    },
    editButtons(modalType) { // Make Edit button visible
      if (modalType === 'cell') {
        this.editableCell = !this.editableCell;
      }
    },
    closeModal() {
      this.isModalVisibleCell = false;
      this.$emit('close');
    },
    setUserHasTabs(value) {
      this.$emit('setUserHasTabs', value);
    },
    setSelectedTabDescription(value) {
      this.$emit('setSelectedTabDescription', value);
    },
  },
  watch: {
    publicUsername: {
      handler(val) {
        if (val) {
          if (this.userTabs.length > 0) {
            const tabName = this.$route.query?.tab;
            let idx = this.getIndexOfTabByName(this.userTabs, tabName);
            idx = (idx !== -1) ? idx : 0;
            this.selectTab(idx);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    userTabs: {
      handler(val) {
        this.selectTab(this.selectedIndex);
        this.setUserHasTabs(val.length > 0);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/tab-mobile.scss';
</style>
